body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: #8c918cscale;
}

::after, ::before, body {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --bar-width: 30px;
  --bar-height: 5px;
  --hamburger-gap: 4px;
  --foreground: #172A4F;
  --background: #172A4F;
  --hamburger-margin: 3vh;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

@font-face {
  font-family: Iceberg;
  src: url('src/assets/fonts/Iceberg-Regular.ttf');
}

@font-face {
  font-family: Inter-Regular;
  src: url('src/assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: Inter-Medium;
  src: url('src/assets/fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: Lexend-Light;
  src: url('src/assets/fonts/Lexend-Light.ttf');
}